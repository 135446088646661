import * as React from 'react';
import instagram from 'images/instagram_white.webp';
import PrivacyConsent from 'components/PrivacyConsent';

const HomeFooter = () => {
  return (
    <footer className={'home'}>
      <PrivacyConsent />
      <div className={'item-container'}>
        <div className={'item'}>
          <a href="/whispr/terms">Terms</a>
        </div>
        <div className={'item'}>
          <a href="/whispr/privacy-policy">Privacy</a>
        </div>
        <div className={'item'}>
          <a href="mailto:support@swipr.cc">Contact us</a>
        </div>
        {/* <div className={'item icon'}>
          <a href="https://www.instagram.com/swipr_official/" target="_blank">
            <img src={instagram} width={19.2} height={19.2} />
          </a>
        </div> */}
      </div>
      <div className={'copy-right'}>
        Swipr Corporation. 2020-2023© All Rights Reserved
      </div>
    </footer>
  );
};

export default HomeFooter;

import React, { useState, useEffect } from 'react';
import ScrollLock from 'react-scrolllock';

import appleDownload from 'images/app_store.webp';
import googleDownload from 'images/google_play.webp';
import menu from 'images/menu_black.webp';

import Meta from 'components/whispr/Meta';
import HomeFooter from 'components/whispr/HomeFooter';
import MobileMenu from 'components/whispr/MobileMenu';

import 'styles/index.scss';

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const onMobileMenuClick = () => {
    setShowMobileMenu((s) => !s);
  };
  return (
    <>
      <header className={'home'}>
        <div className={'container'}>
          <div className="logos-container">
            <div className="bg-color"></div>
            <div className="logos">
              <div className="logo">
                <a href="/">
                  <img src={'/swipr-logo.webp'} />
                </a>
              </div>
              <div className="logo active">
                <a href="/whispr">
                  <img src={'/whispr-logo.webp'} />
                </a>
              </div>
            </div>
          </div>
          <div className={'mobile-menu'}>
            <a href="#" onClick={onMobileMenuClick}>
              <img src={menu} width={32} height={32} />
            </a>
          </div>
        </div>
      </header>
      <MobileMenu show={showMobileMenu} onMobileMenuClick={onMobileMenuClick} />
      <ScrollLock isActive={showMobileMenu} />
    </>
  );
};

const MainContent = () => {
  return (
    <div className={'main-content'}>
      <div className={'container'}>
        <h1>
          What do
          <br />
          Your Friends
          <br />
          <em>THINK ?</em>
          <div className={'app-download'}>
            <div className={'app-download-btn'}>
              <a
                href="javscript:void"
                onClick={() => alert('App is under constructing')}
              >
                <img src={appleDownload} />
              </a>
            </div>
            <div
              className={'app-download-btn'}
              style={{
                marginLeft: 24,
              }}
            >
              <a
                href="javscript:void"
                onClick={() => alert('App is under constructing')}
              >
                <img src={googleDownload} />
              </a>
            </div>
          </div>
        </h1>
        <img className={'background'} src={`/whispr-bg.webp`} />
      </div>
    </div>
  );
};

const MobileMainContent = () => {
  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {
    setIsIOS(/(iPad|iPhone|iPod)/g.test(window.navigator.userAgent));
  }, []);
  return (
    <div className={'mobile-main-content whispr'}>
      <h1 className="whispr">
        What do
        <br />
        Your Friends
        <br />
        <em>THINK ?</em>
      </h1>
      <div className={`app-download-btn ${isIOS ? 'ios' : ''}`}>
        {isIOS ? (
          <a
            href="javscript:void"
            onClick={() => alert('App is under constructing')}
          >
            <img src={appleDownload} width={151} height={48} />
          </a>
        ) : (
          <a
            href="javscript:void"
            onClick={() => alert('App is under constructing')}
          >
            <img src={googleDownload} width={151} height={48} />
          </a>
        )}
      </div>
    </div>
  );
};

const IndexPage = () => {
  return (
    <>
      <Meta />
      <main
        className={'home'}
        style={{
          width: '100%',
        }}
      >
        <Header />
        <MainContent />
        <MobileMainContent />
        <HomeFooter />
      </main>
    </>
  );
};

export default IndexPage;
